import { BrowserModule } from '@angular/platform-browser';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { NgModule, APP_INITIALIZER } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';
import { FormsModule } from '@angular/forms';
import { HttpClientModule } from '@angular/common/http';
import {
    MatMenuModule, MatCheckboxModule, MatSnackBarModule, MatDialogModule, MatSliderModule, MatSidenavModule,
    MatInputModule, MatButtonModule, MatButtonToggleModule, MatOptionModule, MatSelectModule,
    MatProgressBarModule, MatProgressSpinnerModule, MatRadioModule, MatToolbarModule, MatTabsModule, MatGridListModule,
    MatTooltipModule, MatIconModule, MatSlideToggleModule, MatListModule, MatNativeDateModule, MatTableModule,
    MatPaginatorModule, MatSortModule, MatDatepickerModule, MatExpansionModule, MatAutocompleteModule, MatChipsModule, MatCardModule, MatFormFieldModule
  } from '@angular/material';
import { NectarRootModule } from '@brafton/nectar';
import { BfAuthGuard, BfAuthServiceModule, BfAuthGuardModule, BF_AUTH_CONFIG, BF_AUTH_CONFIG_OPTIONS } from '@brafton/skynet-angular-security-service';
import { ImagesModule, API_CONFIG, ImagesAuthInterceptorModule} from '@brafton/images';

import { BytePipe } from './services/byte.pipe';
import { DownloadService } from './services/download.service';
import { ProfilesService } from './services/profile.services';
import { Profile } from './models/models';
import { SearchComponent } from './views/search/search.component';
import { ListViewComponent } from './views/list-view/list-view.component';
import { AppOpticConfig, OPTIC_AUTH_CONFIG, OPTIC_AUTH_CONFIG_OPTIONS, ImagesApiConfig } from './app.optic.config';

import { AppComponent } from './app.component';
import { BfUploaderComponent } from './views/bf-uploader/bf-uploader.component';
import { ViewImageComponent } from './views/view-image/view-image.component';
import { UpdateImageComponent } from './views/update-image/update-image.component';
import { LoginComponent } from "./views/login/login.component";

const appRoutes: Routes = [

    { path: 'list', component: ListViewComponent, canActivate: [BfAuthGuard]},
    { path: 'upload', component: BfUploaderComponent, canActivate: [BfAuthGuard] },
    { path: 'view/:id', component: ViewImageComponent, canActivate: [BfAuthGuard] },
    { path: 'update/:id', component: UpdateImageComponent, canActivate: [BfAuthGuard] },
    { path: 'login', component: LoginComponent },
    { path: '', component: LoginComponent },
    { path: '**', redirectTo: '' }
];


@NgModule({
    declarations: [
        AppComponent,
        BfUploaderComponent,
        ListViewComponent,
        SearchComponent,
        ViewImageComponent,
        UpdateImageComponent,
        LoginComponent,
        BytePipe
    ],
    imports: [

        // core angular modules
        BrowserModule,
        HttpClientModule,
        FormsModule,
        BrowserAnimationsModule,

        // Material
        MatMenuModule, MatCheckboxModule, MatSnackBarModule, MatDialogModule, MatSliderModule, MatSidenavModule,
        MatInputModule, MatButtonModule, MatButtonToggleModule, MatOptionModule, MatSelectModule, MatCardModule,
        MatProgressBarModule, MatProgressSpinnerModule, MatRadioModule, MatToolbarModule, MatTabsModule, MatFormFieldModule,
        MatTooltipModule, MatIconModule, MatSlideToggleModule, MatListModule, MatNativeDateModule, MatTableModule,
        MatPaginatorModule, MatSortModule, MatDatepickerModule, MatExpansionModule, MatAutocompleteModule, MatChipsModule, MatGridListModule, 
        
        // api service modules
        ImagesAuthInterceptorModule,
        ImagesModule.forRoot(),

        // third party modules
        NectarRootModule,
        RouterModule.forRoot(appRoutes),

        // security modules
        BfAuthServiceModule,
        BfAuthGuardModule,
    ],
    providers: [
        ProfilesService,
        DownloadService,
        OPTIC_AUTH_CONFIG,
        { provide: API_CONFIG, useClass: ImagesApiConfig },
        { provide: BF_AUTH_CONFIG, useClass: OPTIC_AUTH_CONFIG },
        { provide: BF_AUTH_CONFIG_OPTIONS, useClass: OPTIC_AUTH_CONFIG_OPTIONS },
        AppOpticConfig,
        // Load JSON Settings from the Server. APP_INITIALIZER executes Config.load() method before application startup. 
        // The 'multi: true' is being used because an application can have more than one line of APP_INITIALIZER.
        { provide: APP_INITIALIZER, useFactory: configFactory, deps: [AppOpticConfig], multi: true }
    ],
    bootstrap: [AppComponent]
})
export class AppModule { }

export function configFactory(config: AppOpticConfig) {
    return function configFunction() {
        return config.load()
    }
}
