import { Component } from '@angular/core';
import { Router } from '@angular/router';
import { ActivatedRoute, NavigationExtras } from '@angular/router';


@Component({
    selector: 'app-search',
    templateUrl: './search.component.html',
    styleUrls: ['./search.component.css']
})

export class SearchComponent {

    constructor(private router: Router, private route: ActivatedRoute) {

    }
    searchImages(needle) {
        let navQuery: NavigationExtras = { queryParams: { 'query': needle } };
        this.router.navigate(['/list'], navQuery);
    }
}
