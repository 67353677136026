import { DownloadService } from './../../services/download.service';
import { Profile } from './../../models/models';
import { ProfilesService } from './../../services/profile.services';
import { Component, OnInit, Pipe, PipeTransform, Renderer, ElementRef } from '@angular/core';
import { BfAuthService } from '@brafton/skynet-angular-security-service';
import { IPagedList, Image, ImagesService } from '@brafton/images';
import { Router, ActivatedRoute, ParamMap } from '@angular/router';
import { AppOpticConfig } from '../../app.optic.config';
import { BytePipe } from '../../services/byte.pipe';
import * as FileSaver from 'file-saver';

@Component({
  selector: 'app-view-image',
  templateUrl: './view-image.component.html',
  styleUrls: ['./view-image.component.css']
})

export class ViewImageComponent implements OnInit {
  image: Image;
  photoId: number;
  profileModified: Profile;
  profileCreated: Profile;
  bytes: number;
  loading: boolean = false;
  public width: number;
  public type: string;
  public download: boolean = false;
  public edit: boolean;
  public imageToShow: any;
  public url: string;
  public filename: string;
  public size: number;


  constructor(
    private imagesService: ImagesService,
    private route: ActivatedRoute,
    private router: Router,
    private authService: BfAuthService,
    private profilesService: ProfilesService,
    private down: DownloadService
  ) { }

  ngOnInit() {
    this.photoId = parseInt(this.route.snapshot.paramMap.get('id'), 10);
    this.getImage(this.photoId);
    this.edit = this.authService.hasPermission('modifyImage', AppOpticConfig.ImagesApiAppName);
  }
  changeText(){
    let button = document.getElementById('original');
    button.innerText = 'Download Sized';
  }
  resetText(){
    let reset = document.getElementById('original');
    if(typeof this.size == "undefined" || this.size < 1){reset.innerText = 'Download Original';}
  }
  formSubmit(event){
    this.download = true;
    let size: number = event.form.value.size;
    this.size = size;
    if(size>0){
      this.url = this.getImageURL(this.photoId,size);
      this.down.GetSized(this.url).subscribe(
        blob=>{ 
                this.createImageFromBlob(blob,size);
                this.download = false;
        }
      );
    } else{
      this.getImageData(this.photoId);
    }
  }

  createImageFromBlob(image: Blob,size?:number) {
      let url = size ? ('x_0_0_0_'+this.photoId+'_'+size+'.jpg') : this.photoId+".jpg";
      FileSaver.saveAs(image, url);
  }

  getImageData(photoId){
    this.down.AltGetAsync( photoId ).subscribe(
      photo => { 
        this.createImageFromBlob(photo);
        this.download = false;
      }
    );
  }

  getSizedImage(id: number,size: number){
    let src = this.getImageURL(id,size);
  }

  getImage( photoId: number ) {
    this.imagesService.GetAsync( photoId ).subscribe(
      image => {
        this.image = image;
        this.width = image.width;
        let ratio = image.height/image.width;
        this.type = ratio <= 1 ? 'landscape' : ( (ratio > 1 && ratio <=5) ? 'portrait' : 'infographic');
        this.profilesService.getProfile(this.image.createdByProfileId ).subscribe(
          profile => {
            this.profileCreated = profile;
          }
        );

        // time to fetch the last modified profile
        if (this.image.modifiedByProfileId) {
            this.profilesService.getProfile(this.image.modifiedByProfileId).subscribe(
                profile => {
                    this.profileModified = profile;
                },
                error => console.log(error)
            );
        }
      },
      error => console.error(error)
    );
  }

  getImageURL( photoId: number, size ) {
    return `${AppOpticConfig.PicturesEndpoint}/x_0_0_0_${photoId}_${size}.jpg`;
  }

}
