import { Component, OnInit, OnDestroy } from '@angular/core';
import { NgForm } from "@angular/forms";
import { Router, ActivatedRoute } from '@angular/router';
import { BfAuthService } from '@brafton/skynet-angular-security-service';
import { ImagesService, UpdateImageRequest, AccessLevel, Image } from '@brafton/images';
import { AppOpticConfig } from '../../app.optic.config';
import { MatSnackBar, MatSnackBarConfig } from '@angular/material';

@Component({
  selector: 'app-update-image',
  templateUrl: './update-image.component.html',
  styleUrls: ['./update-image.component.css']
})
export class UpdateImageComponent implements OnInit, OnDestroy {
  sub: any;
  updatedImage: UpdateImageRequest;
  photoId: number;
  tagArray: string[];
  disabled: boolean;
  deleted: boolean;
  public width: number;
  public type: string;
  restrictions = [AccessLevel.Public, AccessLevel.Private];
  image: Image;

  constructor(private router: ActivatedRoute, private imagesService: ImagesService, private route: Router, private authService: BfAuthService, public snackBar: MatSnackBar) { }

  ngOnInit() {
    this.sub = this.router.params.subscribe(params => this.getImage(params['id']));
  }

  ngOnDestroy() {
    this.sub.unsubscribe(); // Unsubscribe from the params subscription
  }

  public getImageURL(photoId: number, size: number) {
    return `${AppOpticConfig.PicturesEndpoint}/x_0_0_0_${photoId}_${size}.jpg`;
  }

  public formSubmit(event) {
    let values = event.form.value;
    let len = Object.keys(values).length;
    for (var key in values) {
      if (values[key] != "") {
        this.updatedImage[key] = values[key];
      }
    }

    this.updatedImage.accessLevel.id = this.updatedImage.accessLevel.id;

    this.imagesService.UpdateAsync(this.photoId, this.updatedImage).subscribe(
      (updatedImage: Image) => {
        if (updatedImage) {
          // image deleted status changed
          if (this.image.deleted != this.deleted) 
            this.deleted ? this.deleteImage(this.photoId) : this.undeleteImage(this.photoId);       
          // image disabled status changed
          if (this.image.disabled != this.disabled)
            this.disabled ? this.disableImage(this.photoId) : this.enableImage(this.photoId);
          // no status changes, just redirect
          if (this.image.disabled == this.disabled && this.image.disabled == this.disabled) 
           this.onImageUpdated(this.photoId);
        }
      },
      error => this.openSnackBar("Failed to edit image! ERROR [" + <any>error + "]", "OK")
    )
  }

  public openSnackBar(message: string, action?: string) {
    let config = new MatSnackBarConfig();
    config.duration = 4000;
    this.snackBar.open(message, action, config);
  }

  private getImage(photoId: number) {
    this.imagesService.GetAsync(photoId)
      .subscribe(
        (image: Image) => {

          this.updatedImage = {
            accessLevel: image.accessLevel, title: image.title, copyright: image.copyright, description: image.description, focalPointX: image.focalPointX, focalPointY: image.focalPointY,
            tags: image.tags, autoTags_98: image.autoTags_98, autoTags_90: image.autoTags_90, autoTags_85: image.autoTags_85, autoTags_80: image.autoTags_80, autoTags_75: image.autoTags_75,
            source: image.source, modifiedByProfileId: this.authService.profileId
          };

          this.image = image;
          this.photoId = image.photo_id;
          this.tagArray = image.tags.split(",");
          this.disabled = image.disabled;
          this.deleted = image.deleted;
          let ratio = image.height / image.width;
          this.type = ratio <= 1 ? 'landscape' : ((ratio > 1 && ratio <= 5) ? 'portrait' : 'infographic');
        },
      error => this.openSnackBar("Failed to get image! ERROR [" + <any>error + "]", "OK"));
  }

  private undeleteImage(photoId: number) {
    this.imagesService.UndeleteAsync(photoId).subscribe(
      (unDeleted: Image) => {
        if (unDeleted) this.onImageUpdated(photoId);
      },
      error => this.openSnackBar("Failed to enable image! ERROR [" + <any>error + "]", "OK")
    )
  }

  private deleteImage(photoId: number) {
    this.imagesService.DeleteAsync(photoId).subscribe(
      (deleted: Image) => {
        if (deleted)  this.onImageUpdated(photoId);
      },
      error => this.openSnackBar("Failed to enable image! ERROR [" + <any>error + "]", "OK")
    )
  }

  private disableImage(photoId: number) {
    this.imagesService.DisableAsync(photoId).subscribe(
      (disabled: Image) => {
        if (disabled) this.onImageUpdated(photoId);
      },
      error => this.openSnackBar("Failed to disable image! ERROR [" + <any>error + "]", "OK")
    )
  }

  private enableImage(photoId: number) {
    this.imagesService.EnableAsync(photoId).subscribe(
      (enabled: Image) => {
        if (enabled) this.onImageUpdated(photoId);
      },
      error => this.openSnackBar("Failed to disable image! ERROR [" + <any>error + "]", "OK")
    )
  }

  private onImageUpdated(photoId: number) {
    this.openSnackBar("Successfully updated image!");
    this.route.navigate(['/view', photoId])
  }


}
