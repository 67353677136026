import { BfAuthService } from '@brafton/skynet-angular-security-service';
import { Component, OnInit, ViewChild } from '@angular/core';
import { Image, ImagesService, CreateImageRequest, AccessLevel } from '@brafton/images';
import { NgForm } from "@angular/forms";
import { Router } from '@angular/router';
import { AppOpticConfig } from '../../app.optic.config';

declare var jic: any;

@Component({
  selector: 'bf-uploader',
  templateUrl: './bf-uploader.component.html',
  styleUrls: ['./bf-uploader.component.css']
})
export class BfUploaderComponent implements OnInit {
  createRequest: CreateImageRequest; // image object to be passed to the ImagesService for uploading, contains image metadata
  error: any;
  access = AccessLevel;
  files: FileList;
  image: Image;
  loading: boolean = false;
  create: boolean;

  fileValid: boolean;
  
  maxSize: Number = 3145728; // max potential file size (3 MB)...compress image if larger than this
    

  constructor( private imagesService: ImagesService, private router: Router, private bfAuth: BfAuthService) { }

  ngOnInit() {
      // initialize the new Image metadata object
    this.create = this.bfAuth.hasPermission('createImage', AppOpticConfig.ImagesApiAppName);
    this.createRequest = new CreateImageRequest();
    this.createRequest.accessLevel = this.access.Public;
    this.fileValid = true;
    
  }

  // reference to the file input element in our template
  @ViewChild("uploaderForm") daForm: NgForm;
  @ViewChild("daImgInput") imgInput;
  @ViewChild("daPreview") daPreview;

  onSubmit(e) {
    this.loading = true;
    let fi = this.imgInput.nativeElement;
    // let's check if we got an image selected, either break out and display error message or continue
    this.fileValid = !(fi.files[0] === undefined);
    // check that the text fields have been filled out and an image has been selected
    if ( this.daForm.invalid || !this.fileValid ) {
      return false;
    }

    var daFile; // variable to hold our image file
    
    // check image file size, kick off compression if too large
    if ( fi.files[0].size > this.maxSize ) {
      var toCompress = this.daPreview.nativeElement;
      var quality = 95;
      var compressed = jic.compress( toCompress, quality, 'jpg' );

      // the following holds the data URL from the generated <img> returned by JIC
      var compressedURL = compressed.src;

      // use the above URL to generate a blog object, populated with info by tearing apart the data URL
      var blob = this.dataURLToBlob( compressedURL );

      // loop to repeatedly attempt compressing our preview image, reducing quality by 1 each time 'round
      for ( quality = 90; blob.size >= this.maxSize; quality -= 5 ) {
        compressed = jic.compress( toCompress, quality, 'jpg' );
        compressedURL = compressed.src;
        blob = this.dataURLToBlob( compressedURL );
      }
      
      daFile = new File( [blob], fi.files[0].name, {type: fi.files[0].type} );
    } else {
      daFile = fi.files[0];
    }

    // alright, we now know an image has been selected (and compressed), and each required field has been filled out
    // let's do this!

    // Let's bind the inputs from the form into our createRequest object
    this.createRequest.title = this.daForm.form.controls.title.value;
    this.createRequest.description = this.daForm.form.controls.description.value;
    this.createRequest.tags = this.daForm.form.controls.tags.value;
    this.createRequest.source = this.daForm.form.controls.source.value;
    this.createRequest.copyright = this.daForm.form.controls.copyright.value;
    this.createRequest.createdByProfileId = this.bfAuth.profileId;

    this.error = "";

    var source = this.imagesService.CreateAsync(this.createRequest, daFile);
    
    var subscription = source.subscribe(
      image => {
        this.image = image;
        this.router.navigate(['/view', image.photo_id]);
      },
      error => {
        this.error = error;
        this.loading = false;
        console.error(this.error);
      }
    );
    return false;
  }

  // function to pick up a selected image on file input when one is selected
  // use to populate image preview
  imageSelected( fileInput: any ) {
    let fi = this.imgInput.nativeElement;
    let fileReader = new FileReader();

    fileReader.addEventListener("load", ()=>{
      this.daPreview.nativeElement.src = fileReader.result;

    }, false);

    if ( fi.files[0] ) {
      fileReader.readAsDataURL( fi.files[0] );
    }
  }

  // Function to take in a data URL, rip the string apart, and use it to build a Blob (to be converted to a File)
  dataURLToBlob(dataURL) {
    var BASE64_MARKER = ';base64,';
    if (dataURL.indexOf(BASE64_MARKER) == -1) {
        var parts = dataURL.split(',');
        var contentType = parts[0].split(':')[1];
        var raw = decodeURIComponent(parts[1]);
        return new Blob([raw], {type: contentType});
    }
    var parts = dataURL.split(BASE64_MARKER);
    var contentType = parts[0].split(':')[1];
    var raw = window.atob(parts[1]);
    var rawLength = raw.length;
    var uInt8Array = new Uint8Array(rawLength);
    for (var i = 0; i < rawLength; ++i) {
        uInt8Array[i] = raw.charCodeAt(i);
    }
    return new Blob([uInt8Array], {type: contentType});
  }

  get diagnostic() { return JSON.stringify(this.createRequest); }

}
