import { Component, OnInit } from '@angular/core';
import { Router, NavigationExtras } from '@angular/router';
import { BfAuthService } from '@brafton/skynet-angular-security-service';
import { AppOpticConfig } from "../../app.optic.config";

@Component({
    selector: 'app-login',
    templateUrl: './login.component.html',
    styleUrls: ['./login.component.css']
})
export class LoginComponent implements OnInit {

    constructor(private authService: BfAuthService, private route: Router, private opticConfig: AppOpticConfig) {
    }

    ngOnInit() {
        this.authService.login();
    }

}
