import { Pipe, PipeTransform } from '@angular/core';

@Pipe({ name: "bytePipe"})

export class BytePipe implements PipeTransform {
    transform(value: number):number {
        if(value < 1000000){
           return Math.floor(value); 
        } else{
            return value/1000000;
        }
        
    }
}