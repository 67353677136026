import { Injectable, Inject } from '@angular/core';
import { HttpClient, HttpHeaders, HttpErrorResponse, HttpParams, HttpResponse } from '@angular/common/http';
import { Observable, of } from 'rxjs';
import { catchError, map, tap } from 'rxjs/operators';
import { AppOpticConfig } from '../app.optic.config';



@Injectable()
export class DownloadService {
    appName: string = "images-api";
    constructor(private http: HttpClient)
    {
    }

    public AltGetAsync(id: number): Observable<Blob> {
        let delegateToken: string = localStorage.getItem(`id_token_delegate_${this.appName}`);
        let headers: HttpHeaders = new HttpHeaders();
        headers = headers.set('Authorization', `Bearer ${delegateToken}`);
        return this.http.get<Blob>(AppOpticConfig.ImagesApiEndpoint+'/api/images/'+ id+'.jpg', { headers: headers, responseType: 'blob' as 'json' });
            // .pipe(
            //     map( (result:Response) =>  {return result.blob();})
            // );
            //.map((res: Response)=>res.blob())
    }
    public GetSized(url: string): Observable<Blob> {
        return this.http.get<Blob>(url,{ responseType: 'blob' as 'json' });
            //.map((res: Response)=>res.blob())
    }
}
