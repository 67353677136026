import { Component, OnInit, ViewChild } from '@angular/core';
import { ImagesService, Image, AccessLevel, Disabled } from '@brafton/images';
import { IPagedList, Error, ErrorDetails, BfAuthService } from '@brafton/skynet-angular-security-service';
import { ActivatedRoute, Router, Params, NavigationExtras } from '@angular/router';
import { MatPaginator, PageEvent } from '@angular/material';
import { AppOpticConfig } from '../../app.optic.config';

@Component({
    selector: 'app-list-view',
    templateUrl: './list-view.component.html',
    styleUrls: ['./list-view.component.css']
})
export class ListViewComponent implements OnInit {
    error: any;
    current: string = "Loading images..";
    needle: string = '';
    images: Image[];
    isSearch: boolean = false;
    subRoute: string;
    showing: any[] = [];
    cols: number;
    enabled: boolean;
    public signin: boolean;

    // Pagination
    public imagePage: PageEvent;
    @ViewChild(MatPaginator) paginator: MatPaginator;

    constructor(private router: ActivatedRoute, private imagesService: ImagesService, private route: Router, private authService: BfAuthService) {
        this.imagePage = new PageEvent();
    }

    ngOnInit() {
        this.signin = this.authService.hasPermission('signIn', AppOpticConfig.ImagesApiAppName);
        if (this.signin) {
            let width = window.screen.width;
            this.cols = width <= 480 ? 1 : 4;

            this.router.queryParams
                .subscribe((params: Params) => {

                    let page: number = params['page'] == null ? 1 : +params['page'];
                    let pageSize: number = params['pageSize'] == null ? 28 : +params['pageSize'];
                    let query: string = params['query'] == null ? null : params['query'];
                    let disabled: string = params['disabled'] == null ? null : params['disabled'];

                    this.fetchImages(page, pageSize, query, disabled);

                    this.paginator.page.subscribe((event: PageEvent) => {
                        let navQuery: NavigationExtras = {
                            queryParams: {
                                'page': event.pageIndex + 1, 'pageSize': event.pageSize, 'query': query, 'disabled': disabled
                            }
                        };

                        this.route.navigate(['/list'], navQuery);
                    });

                }, error => {
                });

        }
    }

    getImageURL(photoId: number, size: number = 400) {
        return `${AppOpticConfig.PicturesEndpoint}/x_0_0_0_${photoId}_${size}.jpg`;
    }

    fetchImages(page: number, pageSize: number, query: string, disabled: string) {

        let localDisabled: Disabled = null;
        if (disabled != null && disabled.toLowerCase() == "yes") localDisabled = Disabled.Yes;
        if (disabled != null && disabled.toLowerCase() == "no") localDisabled = Disabled.No;

        this.imagesService.ListAsync(page, pageSize, query, null, null, null, null, null, null, localDisabled)
            .subscribe((pagedList: IPagedList<Image>) => {

                this.images = pagedList.items;

                // set paginator
                this.imagePage.length = pagedList.totalItems;
                this.imagePage.pageIndex = pagedList.page - 1;
                this.imagePage.pageSize = pagedList.pageSize;

                //this.images = this.images.filter(item => item.disabled == false);
                this.current = query ? `${pagedList.totalItems} results for ${query}` : "Photo Browser";
            },
            error => console.error(error));
    }
    over(e) {
        //toggle additional data if event target is outside figure element
        let master = e.currentTarget;
        let show = master.getElementsByClassName('mirage');
        show[0].style.display = 'block';
    }
    out(ev) {
        //toggle additional data if event target is outside figure element
        let master = ev.currentTarget;
        let show = master.getElementsByClassName('mirage');
        show[0].style.display = 'none';
    }

}


