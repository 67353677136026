import { ImagesService, IPagedList, Image } from '@brafton/images';
import { Component, HostListener, Inject, OnInit, ViewChild } from '@angular/core';
import { DOCUMENT } from "@angular/platform-browser";
import { BfAuthService } from "@brafton/skynet-angular-security-service";
import { ProfilesService } from './services/profile.services';
import { MatSidenav } from '@angular/material';
declare var require: any;
const { version: appVersion } = require('../../package.json')


@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.css']
})
export class AppComponent implements OnInit {
    public appVersion;
    public create: boolean = false;
    @ViewChild(MatSidenav) private sidenav1: MatSidenav;

  constructor( @Inject(DOCUMENT) private daDocument: any, public authService: BfAuthService) {
      authService.handleAuthentication();
  }
  
  @HostListener("window:resize") windowResize(){
    var height = window.innerHeight;
    this.daDocument.body.style.height = height - 64 + "px";
  }

  ngOnInit() {
      this.windowResize();
      this.appVersion = appVersion;
  }

  public onMenuClick() {
      this.sidenav1.toggle()
  }
}
