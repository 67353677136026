//// details from here - https://gist.github.com/fernandohu/122e88c3bcd210bbe41c608c36306db9
// import { OpaqueToken } from '@angular/core';
import { Auth0, DelegateClient, BfAuthConfig } from '@brafton/skynet-angular-security-service';
import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { throwError, Observable } from 'rxjs';
import { catchError, map, tap } from 'rxjs/operators';
import { API_CONFIG, IApiConfig } from '@brafton/images';


@Injectable()
export class AppOpticConfig {


    public static get Auth0Domain(): string {
        return AppOpticConfig.getConfig('auth0:Domain');
    }

    // Optic app Region

    public static get OpticAuth0ApiClientId(): string {
        return AppOpticConfig.getConfig('optic:Auth0:ClientId');
    }

    public static get OpticAuth0ApiAppName(): string {
        return AppOpticConfig.getConfig('optic:Auth0:AppName');
    }

    public static get OpticAuth0ApiAudienceName(): string {
        return AppOpticConfig.getConfig('optic:Auth0:AudienceName');
    }

    public static get OpticGuiEndpoint(): string {
        return AppOpticConfig.getConfig('optic:Endpoint');
    }

    // pictures

    public static get PicturesEndpoint(): string {
        return AppOpticConfig.getConfig('pictures:EndPoint');
    }

    // Core Api Region
    public static get CoreApiClientId(): string {
        return AppOpticConfig.getConfig('coreApi:ClientId');
    }

    public static get CoreApiAppName(): string {
        return AppOpticConfig.getConfig('coreApi:AppName');
    }

    public static get CoreApiEndpoint(): string {
        return AppOpticConfig.getConfig('coreApi:EndPoint');
    }

    public static get CoreApiLegacy(): boolean {
        return AppOpticConfig.getConfig('coreApi:Legacy');
    }

    // Images Api Region
    public static get ImagesApiClientId(): string {
        return AppOpticConfig.getConfig('imagesApi:ClientId');
    }

    public static get ImagesApiAppName(): string {
        return AppOpticConfig.getConfig('imagesApi:AppName');
    }

    public static get ImagesApiEndpoint(): string {
        return AppOpticConfig.getConfig('imagesApi:EndPoint');
    }

    public static get ImagesApiLegacy(): boolean {
        return AppOpticConfig.getConfig('imagesApi:Legacy');
    }

    private static config: Object = null;
    private static env: EnvData = null;


    /**
     * Use to get the data found in the second file (config file)
     */
    public static getConfig(key: any) {
        return AppOpticConfig.config[key];
    }

    /**
     * Use to get the data found in the first file (env file)
     */
    public static getEnv(key: any) {
        return AppOpticConfig.env[key];
    }

    constructor(private http: HttpClient) { }

    /**
     * This method:
     *   a) Loads "env.json" to get the current working environment (e.g.: 'production', 'development')
     *   b) Loads "config.[env].json" to get all env's variables (e.g.: 'config.development.json')
     */
    public load() {
        return new Promise((resolve, reject) => {
            this.http.get<any>('/assets/environments/env.json')
                .pipe(
                    catchError((error: any): any => {
                        console.log('Configuration file "env.json" could not be read');
                        resolve(true);
                        return throwError(error.error || 'Server error');
                    })
                )
                .subscribe((envResponse: EnvData) => {
                    AppOpticConfig.env = envResponse;
                    let request: any = null;
                    request = this.http.get<any>('./assets/environments/env.' + AppOpticConfig.env.env + '.json');
                    if (request) {
                        request
                            .pipe(
                                catchError((error: any): any => {
                                    console.error('Error reading ' + AppOpticConfig.env + ' configuration file');
                                    resolve(error);
                                    return throwError(error.error || 'Server error');
                                })
                            )
                            .subscribe((responseData) => {
                                AppOpticConfig.config = responseData;
                                resolve(true);
                            });
                    } else {
                        console.error('Env config file "env.json" is not valid');
                        resolve(true);
                    }
                });
        });
    }
}

class EnvData {
    env: string;
}


@Injectable()
export class ImagesApiConfig implements IApiConfig {
    ImagesApiEndPoint: string = AppOpticConfig.ImagesApiEndpoint;
    constructor() { }
}

@Injectable()
export class OPTIC_AUTH_CONFIG implements BfAuthConfig {

    Auth0: Auth0 = {
        Domain: AppOpticConfig.Auth0Domain,
        ClientId: AppOpticConfig.OpticAuth0ApiClientId,
        AppName: AppOpticConfig.OpticAuth0ApiAppName,
        AudienceName: AppOpticConfig.OpticAuth0ApiAudienceName,
    };

    DelegateClients: DelegateClient[] = [
        {
            // images-api delegate
            Auth0: {
                ClientId: AppOpticConfig.ImagesApiClientId,
                AppName: AppOpticConfig.ImagesApiAppName,
                Required: true,
                Legacy: AppOpticConfig.ImagesApiLegacy
            }
          },
        {
            // Core-Api delegate settings
            Auth0: {
                ClientId: AppOpticConfig.CoreApiClientId,
                AppName: AppOpticConfig.CoreApiAppName,
                Required: false,
                Legacy: AppOpticConfig.CoreApiLegacy
            }
        }
    ];
    CallbackURL: string = AppOpticConfig.OpticGuiEndpoint;
    AutoLogin = true;
    LoginPageURL = '/login';
    HomePageURL = '/list?disabled=No';
    ApplicationNameOnAuth0: string = AppOpticConfig.OpticAuth0ApiAppName;
};

@Injectable()
export class OPTIC_AUTH_CONFIG_OPTIONS implements Auth0LockConstructorOptions {

    closable = false;
    languageDictionary: any = { title: 'Login to Optic' };
    configurationBaseUrl: 'https://cdn.us.auth0.com';
    auth: Auth0LockAuthOptions = {
        //audience : AppOpticConfig.OpticAuth0ApiAudienceName,
        // params: {
        //     scope: 'openid given_name family_name email picture profile email https://brafton.com/app_metadata core-api.brafton.com/objects:read images-api.brafton.com/images:read images-api.brafton.com/images:create images-api.brafton.com/images:update',
        // },
        params: {
          scope: 'openid given_name family_name email picture app_metadata'
        },
        sso: true,
        redirectUrl: AppOpticConfig.OpticGuiEndpoint,
        responseType: 'token id_token',
    };
    allowedConnections: string[] = ['Staff-External-Users', 'brafton-com', 'castleford-com-au'];
    defaultDatabaseConnection = 'Staff-External-Users';
    theme: Auth0LockThemeOptions = {
        logo: '/assets/brafton-b.svg',
        primaryColor: '#8bc34a'
    }

    constructor() {
        if (window.location.pathname && window.location.pathname.length > 1) {
            const queryString = window.location.href.split('?')[1]
            const url = (queryString != null && queryString.length >= 2)
                ? `${window.location.pathname}?${queryString}` : window.location.pathname;

            this.auth.params.state = encodeURIComponent(`ru=${url}`);
        }
    }
};
