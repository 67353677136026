import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders, HttpErrorResponse, HttpParams, HttpResponse } from '@angular/common/http';
import { Profile} from '../models/models';
import { Observable, of } from 'rxjs';
import { AppOpticConfig } from '../app.optic.config';

@Injectable()
export class ProfilesService {

    private appName: string;

    constructor(private http: HttpClient) {
        this.appName = AppOpticConfig.CoreApiAppName;
    }

    getProfile(id: number): Observable<Profile> {
        let delegateToken: string = localStorage.getItem(`id_token_delegate_${this.appName}`);
        let headers: HttpHeaders = new HttpHeaders();
        headers = headers.set('Authorization', `Bearer ${delegateToken}`);
        //return this.http.get<Blob>(AppOpticConfig.ImagesApiEndPoint+'/api/images/'+ id+'.jpg', { headers: headers, responseType: 'blob' as 'json' });

        return this.http.get<Profile>(AppOpticConfig.CoreApiEndpoint + '/api/profiles/' + id, { headers: headers});
            // .map(BfAuthHttp.extractData)
            // .catch(BfAuthHttp.handleError);
    }

}
